<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col">
        <div class="card m-auto forecasts-container">
          <div class="card-header p-3 d-flex justify-content-between">
            <h5 class="mt-1 mb-0">
              Forecasts
            </h5>
            <h5 class="mt-1 mb-0">
              <input
                id="filter-text-box"
                v-model="searchQuery"
                type="text"
                placeholder="Search..."
              />
            </h5>
          </div>
          <div
            v-if="forecastData.length"
            class="card-body p-3 d-flex justify-content-center"
            style="height: 50em;"
          >
            <ag-grid-vue
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :pagination="true"
              :paginationAutoPageSize="true"
              :rowData="filteredItems"
              class="ag-theme-alpine h-100 w-100"
            />
          </div>
          <Spinner v-else />
        </div>
        <div class="container-fluid d-flex justify-content-center mt-5">
          <div
            class="blue-link"
            @click="getForecastTemplate"
          >
            Download forecast template
          </div>
        </div>
        <div
          v-if="isInternalRecipientTableVisible"
          class="d-flex justify-content-center mt-5"
        >
          <div
            class="card"
            style="width: 1000px"
          >
            <div class="card-header d-flex justify-content-between">
              <h5 class="mt-1 mb-0">
                Forecast alert internal recipients
              </h5>
              <button
                v-if="isAddInternalRecipientBtnVisible"
                class="btn btn-sm btn-primary"
                @click="addNewInternalRecipientClickHandler"
              >
                Add new
              </button>
            </div>
            <div
              class="card-body p-0"
              style="height: 25em"
            >
              <ag-grid-vue
                :columnDefs="columnInternalRecipientsDefs"
                :defaultColDef="defaultInternalRecipientsColDef"
                :rowData="internalRecipientsRowData"
                class="ag-theme-alpine w-100 h-100 slaEditModal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ForecastEditInternalRecipientModal
    id="forecast-internal-recipient-modal"
    ref="forecastInternalRecipientModal"
    :viewType="modalViewType"
  />
</template>
<script>
import _ from 'lodash';
import Spinner from '@/components/common/Spinner';
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import ForecastEditInternalRecipientModal from '@/components/Forecast/ForecastEditInternalRecipientModal.vue';
import { hasPrivilege } from '@/service/userAccess';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import { booleanIconCellRenderer } from '@/lib/agGridCellRenderers';

export default {
  components: {
    ForecastEditInternalRecipientModal,
    Spinner,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      searchQuery: '',
      rowData: [],
      modalViewType: null
    };
  },
  computed: {
    ...mapGetters({
      forecastData: 'forecast/lastUploadForecasts',
      actualBrands: 'forecast/companyItems',
      internalRecipientsRowData: 'forecast/forecastInternalRecipients'
    }),
    isInternalRecipientTableVisible() {
      return hasPrivilege(userPrivileges.getForecastInternalRecipient);
    },
    isAddInternalRecipientBtnVisible() {
      return hasPrivilege(userPrivileges.createForecastInternalRecipient);
    },
    defaultInternalRecipientsColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnInternalRecipientsDefs() {
      return [
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            visibilityCheck() {
              return hasPrivilege(userPrivileges.updateForecastInternalRecipient);
            },
            clicked: (field) => {
              this.rowRecipientClickHandler(field);
            }
          },
          width: 80
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 300
        },
        {
          headerName: 'Slack Id',
          field: 'slack',
          width: 220
        },
        {
          headerName: 'Receive Lack of Forecast alerts',
          field: 'receiveLackOfForecastAlert',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        },
        {
          field: 'receiveNewForecastAlert',
          headerName: 'Receive New Forecast alerts',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        },
        {
          field: 'receiveForecastDecision',
          headerName: 'Receive Forecast decisions',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        }
      ];
    },
    displayableForecastData() {
      return _.map(this.forecastData, (item) => {
        item.client = this.actualBrands[item['brandId']]?.name;
        return item;
      });
    },
    filteredItems() {
      const queryTrim = this.searchQuery.trim();
      if (queryTrim.length < 1) {
        return this.displayableForecastData;
      }
      const query = queryTrim.toLowerCase();
      return this.displayableForecastData.filter((item) => {
        return item.client.toLowerCase().includes(query);
      });
    },
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefs() {
      return [
        {
          headerName: 'Brand',
          field: 'client',
          sortable: true,
          width: 260
        },
        {
          headerName: 'Last upload date',
          field: 'uploadedDate',
          width: 230,
          sortable: true,
          valueFormatter: ({ value }) => this.formatDate(value)
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 230,
          sortable: true
        },
        {
          headerName: '',
          field: 'brandId',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            buttonText: 'see all',
            buttonVariant: 'btn-primary',
            clicked: (brandId) => {
              this.redirectForecastClickHandler(brandId);
            }
          },
          width: 130
        }
      ];
    }
  },
  async mounted() {
    await this.fetchLastUploadForecasts();
    await this.fetchActualBrands();
    await this.fetchInternalRecipients();
  },
  methods: {
    ...mapActions({
      fetchLastUploadForecasts: 'forecast/fetchLastUploadForecasts',
      downloadForecastTemplate: 'forecast/getForecastTemplate',
      fetchActualBrands: 'forecast/fetchCompanyItems',
      fetchInternalRecipients: 'forecast/fetchForecastInternalRecipients'
    }),
    rowRecipientClickHandler(id) {
      this.modalViewType = modalViewTypes.edit;
      this.$refs.forecastInternalRecipientModal.initModal(id);
    },
    addNewInternalRecipientClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.forecastInternalRecipientModal.initAddModal();
    },
    redirectForecastClickHandler(brandId) {
      return this.$router.push(`/forecasts/${brandId}`);
    },
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        });
      }
    },
    async getForecastTemplate() {
      await this.downloadForecastTemplate();
    }
  }
};
</script>
<style>
.forecasts-container {
  max-width: 900px;
}

.blue-link {
  color: #0d6efd;
  text-decoration: underline;
  transition: color 0.3s, cursor 0.3s;
}

.blue-link:hover {
  cursor: pointer;
  color: #0f489c;
}
</style>

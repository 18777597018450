<template>
  <div
    id="forecast-modal"
    ref="forecastModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="item.email"
              :errors="v$.item.email.$errors"
              description="Email"
              label="Email"
              :readonly="!!item.id"
              @blur="v$.item.email.$touch"
            />
            <TextInput
              v-model="item.slack"
              :errors="v$.item.slack.$errors"
              description="Slack ID"
              label="Slack"
              :readonly="!!item.id"
              @blur="v$.item.slack.$touch"
            />
            <CheckboxInput
              v-model="item.receiveForecastDecision"
              description="You can disable notification about forecast decision for the user."
              label="Receive forecast decisions"
            />
            <CheckboxInput
              v-model="item.receiveLackOfForecastAlert"
              description="You can disable lack of forecast alerts for the user."
              label="Receive lack of forecast alerts"
            />
            <CheckboxInput
              v-model="item.receiveNewForecastAlert"
              description="You can disable new forecast alerts for the user."
              label="Receive new forecast alerts"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              v-if="isDeleteButtonVisible"
              :disabled="isDeleteButtonDisabled"
              class="btn btn-danger"
              @click="showDeleteConfirmationModalHandler"
            >
              Delete
            </button>
            <button
              v-if="isSaveButtonVisible"
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="forecast-recipient-confirmation-modal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The list of recipients will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
  <DeleteConfirmationModal
    ref="forecast-recipient-delete-confirmation-modal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteRecordHandler"
  >
    <template #alert-message>
      Do you really want to delete this recipient email?
      This operation can not be undone!
    </template>
  </DeleteConfirmationModal>
</template>
<script>
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { Modal } from 'bootstrap';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal';
import CheckboxInput from '@/components/common/CheckboxInput';
import { hasPrivilege } from '@/service/userAccess';

const defaultItem = {
  id: null,
  email: null,
  slack: null,
  receiveForecastDecision: false,
  receiveMismatchAlert: false,
  receiveLackForecastAlert: false,
  receiveNewForecastAlert: false
};

export default {
  components: {
    ConfirmationModal,
    DeleteConfirmationModal,
    TextInput,
    Spinner,
    CheckboxInput
  },
  props: ['viewType'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      confirmationModalDelete: null,
      confirmationModalSave: null,
      forecastEditRecipientModal: null,
      initialized: false,
      item: {
        id: null,
        email: null,
        slack: null,
        receiveForecastDecision: false,
        receiveMismatchAlert: false,
        receiveLackOfForecastAlert: false,
        receiveNewForecastAlert: false
      }
    };
  },
  validations() {
    return {
      item: {
        email: {
          email,
          required
        },
        slack: {}
      }
    };
  },
  computed: {
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit row' : 'Add New';
    },
    isSaveButtonDisabled() {
      return !this.initialized;
    },
    isSaveButtonVisible() {
      return hasPrivilege(userPrivileges.updateForecastInternalRecipient);
    },
    isDeleteButtonDisabled() {
      return !this.initialized || this.viewType === modalViewTypes.add;
    },
    isDeleteButtonVisible() {
      return hasPrivilege(userPrivileges.deleteForecastInternalRecipient);
    }
  },
  async mounted() {
    this.forecastEditRecipientModal = new Modal(this.$refs['forecastModal']);
    this.confirmationModalSave = this.$refs['forecast-recipient-confirmation-modal'].confirmationModal;
    this.confirmationModalDelete = this.$refs['forecast-recipient-delete-confirmation-modal'].confirmationModal;
    this.confirmationModalSave.hide();
    this.confirmationModalDelete.hide();
  },
  methods: {
    ...mapActions({
      fetchRecipientById: 'forecast/fetchInternalRecipientById',
      fetchRecipients: 'forecast/fetchForecastInternalRecipients',
      deleteRecipientItem: 'forecast/deleteInternalRecipient',
      addNewRecipient: 'forecast/addInternalRecipient',
      updateRecipient: 'forecast/updateInternalRecipient'
    }),
    async initModal(itemId) {
      this.initialized = false;
      this.forecastEditRecipientModal.show();
      const itemToEdit = itemId ? await this.fetchRecipientById(itemId) : { ...defaultItem };
      this.updateItemData(itemToEdit);
      this.initialized = true;
    },
    initAddModal() {
      this.forecastEditRecipientModal.show();
      const itemToAdd = {
        id: null,
        email: null,
        slack: null,
        receiveForecastDecision: false,
        receiveMismatchAlert: false,
        receiveLackOfForecastAlert: false,
        receiveNewForecastAlert: false
      };
      this.updateItemData(itemToAdd);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.item.id = newValue.id;
      this.item.email = newValue.email;
      this.item.slack = newValue.slack;
      this.item.receiveForecastDecision = newValue.receiveForecastDecision;
      this.item.receiveMismatchAlert = newValue.receiveMismatchAlert;
      this.item.receiveLackOfForecastAlert = newValue.receiveLackOfForecastAlert;
      this.item.receiveNewForecastAlert = newValue.receiveNewForecastAlert;
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.forecastEditRecipientModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.item
      };
      try {
        this.item.id ? await this.updateRecipient(dataToSave) : await this.addNewRecipient(dataToSave);
        const message = this.item.id ? 'Recipient successfully updated' : 'Recipient successfully added';
        this.$toast.success(message);
        await this.fetchRecipients();
      } catch (e) {
        this.$toast.error('Failed to save the recipient.<br/>'+ e);
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
    showDeleteConfirmationModalHandler() {
      this.forecastEditRecipientModal.hide();
      this.confirmationModalDelete.show();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
      this.editModal.show();
    },
    async deleteRecordHandler() {
      try {
        await this.deleteRecipientItem(this.item.id);
        await this.fetchRecipients();
      } catch (e) {
        this.$toast.error('Failed to delete item.');
      }
    }
  }
};
</script>
<style scoped></style>
